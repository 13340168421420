<template>
<div>
  <div class="row">
    <div class="col-md-12 card">
      <div class="card-body row">
        <div class="col-sm-6">
          <div class="card-header">
            <h5 class="card-title">Unexported Bookings</h5>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <div class="btn-group">
              <p-button type="primary" round outline @click="resetTable()">Reset</p-button>
              <download-excel
                class   = "btn btn-default"
                :getData= "getData"
                :isDataValid="isDataValid"
                :exportFields = "exportFields"
                name = "booking-export.csv">
              </download-excel>
              <p-button type="primary" round outline @click="updateBookingsPrompt()">Update Bookings to Exported</p-button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body row">
        <div class="col-sm-12 mt-2">
          <div>
            <vue-good-table
              :theme="tableTheme"
              ref="accountBookingsGrid"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"

              :columns="columns"
              :rows="rows"

              :rowStyleClass="rowStyleClassFn"

              :pagination-options="{
                enabled: true,
                mode: 'records',

                perPage: serverParams.perPage,
                setCurrentPage: serverParams.page,

                position: 'bottom',
                perPageDropdown: [3, 7, 9],
                dropdownAllowAll: false,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
                }"
              :sort-options="{
                enabled: true,
                initialSortBy: serverParams.sort
                }">
                <template slot="table-row" slot-scope="props">

                  <span v-if="props.column.field == 'actions'">
                    <p-button type="success" size="sm" icon @click="handleEdit(props.$index, props.row)">
                      <i class="fa fa-edit"></i>
                    </p-button>
                  </span>

                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
   <div>
      <modal :show.sync="modals.classic" headerClasses="justify-content-center">
        <h4 slot="header" class="title title-up">Update Bookings to Exported</h4>
        <p>Do you want to update the booking records as exported?</p>
        <p>Only do this if the bookings listed have been successfully imported in the accounts system.</p>
        <p>Note once marked as exported the bookings will no longer appear on this page.</p>
        <p>If a booking record is not successfully imported into your accounts system you will need to update the booking record to set 'Exported to Accounts' as No.</p>
        <template slot="footer">
          <p-button type="default" @click.native="modals.classic = false">No</p-button>
          <p-button type="primary" @click.native="updateBookings">Yes</p-button>
        </template>
      </modal>
    </div>
    <div>
      <modal :show.sync="modals.invalidDataAlert" headerClasses="justify-content-center">
        <h4 slot="header" class="title title-up">Export Bookings</h4>
        <p>You have invalid records in the list.</p>
        <p>Please filter them out or correct them.</p>
        <template slot="footer">
          <p-button type="default" @click.native="modals.invalidDataAlert = false">Ok</p-button>
        </template>
      </modal>
    </div>
  </div>

</template>
<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'
  import * as CONSTS from '../../../consts/constants'
  import * as bookingMappings from './sagebookingimport'

  import { Card, Button, Modal } from 'src/components/UIComponents'
  import JsonExcel from './JsonExcel'

  Vue.component('downloadExcel', JsonExcel)

  var numeral = require("numeral");

  export default{
    components: {
      Modal
    },
    computed: {
      ...mapGetters(['userSelectedProduction','userSelectedProductionCcy', 'gridStates', 'tableTheme']),

      productionSelected () {
        return this.$store.getters.userSelectedProduction
      }
    },
    mounted () {
      this.updateTableFilters()

    },
    updated(){

    },
    created () {
      let gridState = this.gridStates[this.gridName]

      if (gridState){
        this.serverParams = gridState
      }
      this.generateColumns()
      this.loadData()

    },
    watch: {
      productionSelected (userSelectedProduction, oldValue) {
       this.loadData()
      }
    },
    data () {
      return {
        gridName: 'accountBookingsGrid',
        serverParams: {
          gridName: 'accountBookingsGrid',
          columnFilters: {
          },
          // sort: {
          //   field: 'company_name', type: 'asc'
          // },
          page: 1,
          perPage: 10
        },
        columns: [
          {
            label: 'Actions',
            field: 'actions'
          },
          {
            label: 'Valid',
            field: 'valid',
            // type: 'number',
            formatFn: this.formatValid,

            // tdClass: this.setRequiredClass,

            filterOptions: {
              enabled: true,
              filterDropdownItems: CONSTS.strings.no_yes
            }
          }
        ],
        exportFields: {},
        rows: [],

        modals: {
          classic: false,
          invalidDataAlert: false
        }
      }
    },
    methods: {

      setDateRequiredClass: function(row) {
            // console.log(row)
            if (!row.Date){
              return "invalidCell";
            }
            return "tablecell"
        },
        setProjRefRequiredClass: function(row) {
          // console.log('setProjRefRequiredClass:'+row.ProjectRefn)
          // if (!row.ProjectRefn  || row.ProjectRefn == 0){
          //     return "invalidCell";
          //   }
            return "tablecell"
        },
        setNominalRequiredClass: function(row) {
          // console.log('setNominalRequiredClass')
          if (!row['NominalA/CRef']){
              return "invalidCell";
            }
            return "tablecell"
        },
        setReferenceRequiredClass: function(row) {
          // console.log('setReferenceRequiredClass')
          if (!row.Reference){
              return "invalidCell";
            }
            return "tablecell"
        },

        setAccReferenceRequiredClass: function(row) {
          // console.log('setReferenceRequiredClass')
          if (!row.AccountReference){
              return "invalidCell";
            }
            return "tablecell"
        },
        setDetailsRequiredClass: function(row) {
          // console.log('setReferenceRequiredClass')
          if (!row.Details){
              return "invalidCell";
            }
            return "tablecell"
        },

        formatValid: function(value) {
          let obj = this.searchArray(CONSTS.strings.no_yes, value, 'value')
          if (obj) {
            return obj.text
          }
        },
        searchArray: function(array, key, prop){
          // Optional, but fallback to key['name'] if not selected
          prop = (typeof prop === 'undefined') ? 'name' : prop;

          for (var i=0; i < array.length; i++) {
              if (array[i][prop] === key) {
                  return array[i];
              }
          }
          return null
      },

      generateColumns(){

        let vm = this
        let fieldObj = {}

        for (let i = 0; i < bookingMappings.mappings.length; i++) {

          const mapping = bookingMappings.mappings[i]
          let fieldNoSpace = mapping.label.split(" ").join("")

          let col = {
            field: fieldNoSpace,  // this is the label from the mapping
            dataField: mapping.field,  // this is the data field
            label: mapping.label,
            sortable: true,
            tdClass: 'tablecell',
            thClass: 'tablecell',
            // tdClass: vm.setRequiredClass,
            filterOptions: {
              enabled: true,
              placeholder: 'Filter'
            }
          }

          // set mandatory format check

          // if(!row['billingdate']  || !row['projectcode'] || !row['nominalcode'] || !row['invoicenumber']  ){
          //     newRow['valid'] = 0
          //   }

          // billingdate -> Date
          // projectcode -> ProjectRefn
          // nominalcode -> NominalA/CRef
          // invoicenumber -> Reference

          // account_code -> AccountReference
          // package_name -> Details

          if (fieldNoSpace  == 'Date' ){
            col['tdClass'] = this.setDateRequiredClass
          }

          // if (fieldNoSpace  == 'ProjectRefn' ){
          //   col['tdClass'] = this.setProjRefRequiredClass
          // }

          if (fieldNoSpace  == 'NominalA/CRef' ){
            col['tdClass'] = this.setNominalRequiredClass
          }

          if (fieldNoSpace  == 'Reference' ){
            col['tdClass'] = this.setReferenceRequiredClass
          }

          if (fieldNoSpace  == 'AccountReference' ){
            col['tdClass'] = this.setAccReferenceRequiredClass
          }

          if (fieldNoSpace  == 'Details' ){
            col['tdClass'] = this.setDetailsRequiredClass
          }


          this.columns.push(col)

          // console.log(fieldNoSpace)
          // console.log(mapping.field)

          // construct the object that lists the fields to export
          fieldObj[mapping.label] = fieldNoSpace
        }

        this.exportFields = fieldObj
      },

      //
      updateTableFilters() {

        // interate through cols starting at 1 to account for the actions button
        for (let i = 1; i < this.columns.length; i++) {
          const col = this.columns[i];
          if (typeof col.filterOptions !== 'undefined') {

            let filterVal = {}

            if ( (filterVal = this.serverParams.columnFilters[ this.columns[i].field ] ) ) {

              let filter = this.columns[i].filterOptions
              filter.filterValue = filterVal
              // seems bonkers having to do this
              let  filterString = JSON.stringify(filter)
              let filterObj = JSON.parse(filterString)

              this.$set(this.columns[i], 'filterOptions', filterObj )
            }
          }
        }
      },
      resetTable: function() {

        this.$refs['accountBookingsGrid'].reset()
        this.serverParams.columnFilters = []
        this.$store.dispatch('setGridState', this.serverParams )
      },

      // table events
      onPageChange(params) {
        this.serverParams.page = params.currentPage
        this.$store.dispatch('setGridState', this.serverParams )
      },

      onPerPageChange(params) {
        this.serverParams.perPage = params.currentPerPage
        this.$store.dispatch('setGridState', this.serverParams )
      },

      onSortChange(params) {
        if (params.columnIndex){
        
          let newSort = {
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
          }

          this.serverParams.sort = newSort
          this.$store.dispatch('setGridState', this.serverParams )
        }
      },

      onColumnFilter(params) {
        this.serverParams.columnFilters = params.columnFilters
        this.$store.dispatch('setGridState', this.serverParams )

        // console.log(this.rows)
      },

      // end table events
      findMappingField: function(field) {

        // start at 1 to account for actions button
        for (let j = 1; j < this.columns.length; j++) {
          if ( this.columns[j].dataField === field){
                 return this.columns[j].field
              }
        }
        return false
      },
      loadData: function () {
        this.$http.get( '/accounts/bookings' )

        .then(response => {

          let retData = response.data

          // for each row create the full mapping object
          for (let j = 0; j < retData.length; j++) {

            let row = retData[j]
            let newRow = {}

            // start at 1 to account for actions
            for (let i = 1; i < this.columns.length; i++) {

              const column = this.columns[i]
              newRow[ column.field ] = ''
            }

            for (var property in row) {

                if (row.hasOwnProperty(property)) {

                    let field = this.findMappingField(property)
                    if ( field !== false){
                      if (row[property]=== null ) row[property] = ''
                      newRow[field] = row[property]
                    }
                }
            }

            // add a column to store the guid
            newRow['guid'] = row['guid']

            // add a validation status
            newRow['valid'] = 1

            // default some values
            // newRow['CostCodeRefn'] = 'DIR'
            newRow['Type'] = 'SI'
            newRow['ProjectRefn'] = ''


            // array_push($a, $rs->Fields('price') - $rs->Fields('discount') ); //Net Amount
            if (isNaN(row['discount'] ) == false && row['discount']  > 0) {

              // recalc the net amount
              let discount = row['discount'] * 100
              let price = row['price'] * 100

              let netAmount = price - discount;
              netAmount = (netAmount / 100).toFixed(2);

              newRow['NetAmount'] = netAmount

            } else {
              newRow['NetAmount'] = row['price']
            }

            if (isNaN(row['vatamount'] ) == false && row['vatamount']  > 0) {
                // array_push($a, "T1"); //Tax Code
                newRow['TaxCode'] = 'T1'
            } else {
                newRow['TaxCode'] = 'T0'
                // array_push($a, "T0"); //Tax Code
            }

            // console.log(newRow)

            // validations
            if ( this.isStringEmpty (newRow.AccountReference) ){
                newRow['valid'] = 0
            }

  //
            // console.log('nc'+row['nominalcode'])
            // console.log('in'+row['invoicenumber'])

            // billingdate -> Date
          // projectcode -> ProjectRefn
          // nominalcode -> NominalA/CRef
          // invoicenumber -> Reference

          // account_code -> AccountReference
          // package_name -> Details
          // || !row['projectcode']
            if(!row['billingdate']  || !row['nominalcode'] || !row['invoicenumber'] || !row['account_code']  || !row['package_name']  ){
              newRow['valid'] = 0
            }

            this.rows.push(newRow)
          }
        })
        .catch(error => {

          console.log(error)

          this.$notify({
            message: 'There was a problem loading the bookings. Please try again.',
            type: 'warning'
          })
        })
      },
      getData(){
        return this.$refs.accountBookingsGrid.filteredRows[0].children
      },
      isDataValid(){

        for (let i = 0; i < this.$refs.accountBookingsGrid.filteredRows[0].children.length; i++) {

          if (this.$refs.accountBookingsGrid.filteredRows[0].children[i].valid === 0){
            this.modals.invalidDataAlert = true
            return false
          }
        }
        return true

      },
      handleEdit (index, row) {

        // console.log(row)
        try {

          this.$router.push({
            name: "Booking",
            params: { id: row.guid, bookingmode: CONSTS.BOOKING }
          });
          // this.$router.push({ name: 'Booking', params: { id: row.guid }})
        }
        catch(e){
          console.log(e)
        }
      },
      updateBookingsPrompt(){
        this.modals.classic = true
      },

      isStringEmpty(s) {
        return (s.length === 0 || !s.trim())
      },
      rowStyleClassFn(row) {

        // return row.valid !== 0 ? 'validRow' : 'invalidRow'
      },
      updateBookings(){
        this.modals.classic = false

        let payload = []

        for (let i = 0; i < this.$refs.accountBookingsGrid.filteredRows[0].children.length; i++) {

          // for (let i = 0; i < this.rows.length; i++) {

          let obj = {
            guid: this.rows[i].guid
          }
          payload.push(obj)
        }

        this.$http.post( '/accounts/bookings/exported/', payload )
        .then(response => {

          if (response.data.hasOwnProperty("apiresponse") && response.data.apiresponse.status !== 'success'){
            this.$notify({
            message: 'There was a problem updating the bookings. Please try again. [1]',
            type: 'warning'
          })
          } else {
              console.log(response.data)
              // do something with the response

            }
          })
        .catch(error => {
          // console.log(error)
          this.$notify({
            message: 'There was a problem updating the bookings. Please try again. [2]',
            type: 'warning'
          })
        })
        .catch(error => {
          console.log(error)
          this.$notify({
            message: 'There was a problem updating the bookings. Please try again.',
            type: 'warning'
          })
        })
      }
    }


  }
</script>
<style lang="scss">

  .tablecell {
   font-size: 14px;
 }

 .validRow {
  //  background-color:#ffffff;
   color:#606266;
 }

.invalidRow {
   background-color:#ef8157;
  //  color:#ef8157;
   color:#ffffff;
  //  opacity: 0.3;
 }

 .invalidCell {
   background-color:#ef8157;
  //  color:#ef8157;
   color:#ffffff;
  //  opacity: 0.3;
 }


</style>
