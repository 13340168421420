export const mappings = [

  { label: 'Type', field: '' },
  { label: 'Account Reference', field: 'account_code' },
  { label: 'Nominal A/C Ref', field: 'nominalcode' },
  { label: 'Department Code', field: '' },
  { label: 'Date', field: 'billingdate' },
  { label: 'Reference', field: 'invoicenumber' },
  { label: 'Details', field: 'package_name' },
  { label: 'Net Amount', field: '' },   // price - discount
  { label: 'Tax Code', field: '' },
  { label: 'Tax Amount', field: 'vatamount' },
  { label: 'Exchange Rate', field: '' },
  { label: 'Extra Reference', field: 'production_name' },
  { label: 'User Name', field: '' },
  { label: 'Project Refn', field: 'projectcode' },
  { label: 'Cost Code Refn', field: '' }
]
